<template>
    <div class="form-wrapper" :class="formState">
        <div class="inner-wrapper">
            <div class="form">
                <form
                    action=""
                    @focusout="change"
                    @input="change"
                    @submit="submit"
                    novalidate
                >
                    <div class="form-group">
                        <div
                            class="input-field-wrapper"
                            :class="{
                                invalid: validation.firstname.errorMessage,
                            }"
                        >
                            <label for="firstname">First name</label>
                            <div class="input-field">
                                <input
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    data-rules='["required"]'
                                    v-model="firstname"
                                />
                            </div>
                        </div>
                        <p
                            class="error-message"
                            v-if="validation.firstname.errorMessage"
                        >
                            {{ validation.firstname.errorMessage }}
                        </p>
                    </div>

                    <div class="form-group">
                        <div
                            class="input-field-wrapper"
                            :class="{
                                invalid: validation.lastname.errorMessage,
                            }"
                        >
                            <label for="lastname">Last name</label>
                            <div class="input-field">
                                <input
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    data-rules='["required"]'
                                    v-model="lastname"
                                />
                            </div>
                        </div>
                        <p
                            class="error-message"
                            v-if="validation.lastname.errorMessage"
                        >
                            {{ validation.lastname.errorMessage }}
                        </p>
                    </div>

                    <div class="form-group">
                        <div
                            class="input-field-wrapper"
                            :class="{ invalid: validation.email.errorMessage }"
                        >
                            <label for="email">Email</label>
                            <div class="input-field">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    data-rules='["required","email"]'
                                    v-model="email"
                                />
                            </div>
                        </div>
                        <p
                            class="error-message"
                            v-if="validation.email.errorMessage"
                        >
                            {{ validation.email.errorMessage }}
                        </p>
                    </div>

                    <div class="form-group">
                        <div
                            class="
                                input-field-wrapper
                                phone-input-field-wrapper
                            "
                            :class="{
                                invalid:
                                    validation.countryCode.errorMessage ||
                                    validation.phone.errorMessage,
                            }"
                        >
                            <label for="phone">Phone</label>
                            <div class="input-field-inner-wrapper">
                                <div class="country-code-dropdown">
                                    <select
                                        name="countryCode"
                                        placeholder="Search"
                                        ref="countryCodeDropdownEl"
                                        data-rules='["required"]'
                                        v-model="countryCode"
                                    ></select>
                                </div>
                                <div class="input-field">
                                    <input
                                        type="tel"
                                        name="phone"
                                        id="phone"
                                        data-rules='["required"]'
                                        v-model="phone"
                                    />
                                </div>
                            </div>
                        </div>
                        <p
                            class="error-message"
                            v-if="
                                validation.countryCode.errorMessage ||
                                validation.phone.errorMessage
                            "
                        >
                            {{ validation.phone.errorMessage }}
                            <span v-if="!validation.phone.errorMessage">{{
                                validation.countryCode.errorMessage
                            }}</span>
                        </p>
                    </div>

                    <div class="form-group">
                        <div
                            class="input-field-wrapper"
                            :class="{ invalid: validation.email.errorMessage }"
                        >
                            <label for="password">Password</label>
                            <div class="input-field">
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    data-rules='["required", "password"]'
                                    v-model="password"
                                />
                            </div>
                        </div>

                        <p
                            class="error-message"
                            v-if="validation.password.errorMessage"
                        >
                            {{ validation.password.errorMessage }}
                        </p>
                    </div>

                    <div class="form-error" v-if="error">
                        <div class="error">{{ error }}</div>
                    </div>

                    <button type="submit" class="">Register</button>
                </form>
            </div>
            <div class="loading-overlay">
                <div class="loader"></div>
            </div>
        </div>
    </div>
</template>

<script>
/* global gtag */

import * as TomSelect from "tom-select";
import formValidation from "@/mixins/formValidation";

export default {
    name: "RegisterForm",
    mixins: [formValidation],
    data() {
        return {
            validation: {
                inputElements: [],
                firstname: {},
                lastname: {},
                email: {},
                countryCode: {},
                phone: {},
                password: {},
            },
            firstname: "",
            lastname: "",
            email: "",
            countryCode: "",
            phone: "",
            password: "",
        };
    },
    mounted() {
        this.$store.dispatch("fetchCountries").then(() => {
            this.initCountryCodeDropdown();
        });

        this.initValidation();

        //this.error = this.$route.query['error-message'];
    },
    methods: {
        initCountryCodeDropdown() {
            const countries = JSON.parse(
                JSON.stringify(this.$store.state.countries)
            );
            const flagsBaseUrl = this.$store.state.flagsBaseUrl;

            const defaultValue = "AE";
            this.countryCode = defaultValue;

            new TomSelect(this.$refs.countryCodeDropdownEl, {
                valueField: "code",
                searchField: ["name", "dialCode"],
                options: countries,
                maxOptions: 300,
                render: {
                    option: function (data, escape) {
                        return (
                            '<div class="country-code">' +
                            '<div class="flag-wrapper">' +
                            '<div class="flag" style="background-image: url(\'' +
                            flagsBaseUrl +
                            data.flag +
                            "')\"></div>" +
                            "</div>" +
                            '<div class="name">+' +
                            escape(data.dialCode) +
                            "</div>" +
                            "</div>"
                        );
                    },
                    item: function (data, escape) {
                        return (
                            '<div class="country-code">' +
                            '<div class="flag-wrapper">' +
                            '<div class="flag" style="background-image: url(\'' +
                            flagsBaseUrl +
                            data.flag +
                            "')\"></div>" +
                            "</div>" +
                            '<div class="name">+' +
                            escape(data.dialCode) +
                            "</div>" +
                            "</div>"
                        );
                    },
                },
                items: defaultValue,
            });
        },
        send() {
            this.error = null;
            this.success = null;

            this.$store
                .dispatch("register", {
                    firstName: this.firstname,
                    lastName: this.lastname,
                    countryCode: this.countryCode,
                    mobile: this.phone,
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    //(response)

                    //Event snippet for Sign-up conversion page
                    gtag("event", "conversion", {
                        send_to: process.env.VUE_APP_GOOGLE_ADS_SIGNUP_ID,
                    });

                    this.formState = null;
                    let redirectPath = "/addresses";
                    if (this.$store.state.afterLoginRedirectPath != null) {
                        redirectPath = this.$store.state.afterLoginRedirectPath;
                    }
                    this.$router.push({ path: redirectPath });
                    //this.success = 'Your account is created';
                })
                .catch((error) => {
                    this.formState = null;
                    this.error = error;
                });
        },
    },
};
</script>

<style lang="scss">
@import "../../scss/partials/variables.scss";
@import "../../../node_modules/tom-select/dist/css/tom-select.css";
@import "../../scss/partials/tom-select-overrides.scss";
</style>
