<template>
  <div class="auth-form-view">
    <div class="container l">
      <div class="auth-form register-form">
        <div class="page-message" v-if="isGettingQuote()">
          Please login to get a quote.
        </div>
        <div class="tabs">
          <div><router-link to="/login">Login</router-link></div>
          <div><router-link to="/register">Register</router-link></div>
        </div>

        <RegisterForm />
      </div>
    </div>
  </div>
</template>

<script>
import RegisterForm from '@/components/auth/RegisterForm.vue';

export default {
  name: 'RegisterView',
  components: {
    RegisterForm,
  },
  methods : {
    isGettingQuote() {
      return this.$store.state.afterLoginRedirect != null && this.$store.state.afterLoginRedirect.path == '/quote';
    }
  }
};
</script>

<style lang="scss">
@import "../scss/partials/variables.scss";
@import '../scss/partials/auth-form-view.scss';
</style>
